import $ from 'jquery';

const BUTTON_ID = 'header__menu-button';
const NAVIGATION_ID = 'header__item--nav';
const mobileNavigation = {};

function toggle() {
  $(`#${+BUTTON_ID}`).toggleClass(`${BUTTON_ID}'--open'`);
  $(`#${NAVIGATION_ID}`).toggleClass(`${NAVIGATION_ID}--open`);
  $('#header').toggleClass('header--navigation-open');
}

function close() {
  $(`#${+BUTTON_ID}`).removeClass(`${BUTTON_ID}'--open'`);
  $(`#${NAVIGATION_ID}`).removeClass(`${NAVIGATION_ID}--open`);
  $('#header').removeClass('header--navigation-open');
}

mobileNavigation.toggle = toggle;
mobileNavigation.close = close;

export default mobileNavigation;
