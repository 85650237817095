import '../styles/index.scss';
import $ from 'jquery';
import 'slick-carousel';
import SliderInit from './sliderInit';
import formSubmition from './modules/formSubmition';
import mobileNavigation from './modules/mobileNavigation';

window.jQuery = $;
window.$ = $;

function anchor(className) {
  $(className).on('click', (event) => {
    mobileNavigation.close();
    event.preventDefault();
    const id = $(event.target).attr('href');
    const top = $(id).offset().top;

    $('body,html').animate({ scrollTop: top }, 1000);
  });
}

$(document).ready(() => {
  // Partners slider
  SliderInit($);

  // Anchors animation
  anchor('.anchor');

  const PREFIX = '#form-get-in-touch__';

  // Form Sumbit Event
  formSubmition('#form-get-in-touch', () => {
    $(`${PREFIX}form`).hide();
    $(`${PREFIX}success`).show();
  });

  $('#form-get-in-touch__recover').click(() => {
    $(`${PREFIX}form`).show();
    $(`${PREFIX}success`).hide();
  });

  // Mobile navigation
  $('#header__menu-button').on('click', () => {
    mobileNavigation.toggle();
  });

  // Close navigation on anchor
});
