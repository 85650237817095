import $ from 'jquery';
import validate from '../helpers/validate';

const SUCCESS_MESSAGE = 'Thanks!';

function sentMessage(obj) {
  $.post(obj.action, obj.data, () => {
    $(obj.form).find('input, textarea').val('');

    if (obj.callback) {
      obj.callback(obj.successMessage);
    }
  });
}

export default (formName, callback) => {
  $(formName).on('submit', (event) => {
    event.preventDefault();

    // Collect data
    const data = {};
    const that = event.target;
    let action = null;
    let validationStatus = true;
    let successMessage;

    action = $(that).attr('action');

    // Message only
    successMessage = $(that).attr('success-message');

    if (!successMessage) {
      successMessage = SUCCESS_MESSAGE;
    }

    // Validate each
    $(that).find('.input').each((index, element) => {
      const input = $(element).find('.input__input');
      const inputName = input.attr('name');
      const value = input.val().trim();
      let inputType = input.attr('type');
      const validation = Number($(element).attr('input-require'));

      if (!inputType) {
        inputType = 'text';
      }

      const validationResult = validate({
        type: inputType,
        value,
        minLength: validation,
      });

      if (!validationResult) {
        $(element).addClass('input--err');
        validationStatus = false;
        return false;
      }

      data[inputName] = value;
      $(element).removeClass('input--err');
      return true;
    });

    // If validated run AJAX
    if (validationStatus) {
      sentMessage({
        action,
        data,
        successMessage,
        callback,
        form: that,
      });
    }
  });
};
