import $ from 'jquery';

// value, type, minLength
export default function validate(params) {
  const EMAIL_REGULAR_EXP = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  function validateEmail(value) {
    if (EMAIL_REGULAR_EXP.test(value)) {
      return true;
    }
    return false;
  }

  let type;
  let value;
  let minLength;

  if (params.value) {
    value = params.value;
  } else {
    return false;
  }

  if (params.type) {
    type = params.type;
  } else {
    type = 'text';
  }

  if (params.minLength) {
    minLength = params.minLength;
  } else {
    minLength = 0;
  }

  if (type === 'text') {
    if (minLength >= value.length) {
      $(this).addClass('input--err');
      return false;
    }
  }

  if (type === 'email') {
    if (!validateEmail(value)) {
      $(this).addClass('input--err');
      return false;
    }
  }

  $(this).removeClass('input--err');
  return value;
}
